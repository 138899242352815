<template>
  <!-- Slideshow container -->
  <div class="slideshow-container">
    <!-- Full-width images with number and caption text -->
    <div class="mySlides fade">
      <img src="https://ucarecdn.com/264f4c0c-1b00-4709-ae97-e817350f1f7e/-/preview/-/quality/smart/"
        style="width: 100%" />
      <!-- <div class="text">Caption Text</div> -->
    </div>

    <div class="mySlides fade">
      <img src="https://ucarecdn.com/15e98afc-9fab-496e-8de0-785d9a96b098/-/preview/-/quality/smart/"
        style="width: 100%" />
      <!-- <div class="numbertext">3 / 3</div> -->
      <!-- <div class="text">Caption Three</div> -->
    </div>

    <div class="mySlides fade">
      <img src="https://ucarecdn.com/8158df51-b1df-4dbb-ac90-4cac8cd52e35/-/preview/-/quality/smart/"
        style="width: 100%" />
      <!-- <div class="numbertext">2 / 3</div> -->
      <!-- <div class="text">Caption Two</div> -->
    </div>

    <div class="mySlides fade">
      <img src="https://ucarecdn.com/b2d1dc67-6fb8-4cfb-93d0-00f774cb9c3d/-/preview/-/quality/smart/"
        style="width: 100%" />
      <!-- <div class="numbertext">3 / 3</div> -->
      <!-- <div class="text">Caption Three</div> -->
    </div>



    <!-- Next and previous buttons -->
    <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
    <a class="next" onclick="plusSlides(1)">&#10095;</a>
  </div>
  <br />

  <!-- The dots/circles -->
  <div style="text-align: center">
    <span class="dot" onclick="currentSlide(1)"></span>
    <span class="dot" onclick="currentSlide(2)"></span>
    <span class="dot" onclick="currentSlide(3)"></span>
    <span class="dot" onclick="currentSlide(4)"></span>
  </div>
</template>

<script>
  export default {
    mounted() {
      let externalScript = document.createElement('script')
        externalScript.setAttribute('src', '/js/slideshow.js')
        document.head.appendChild(externalScript)
    },
  };
</script>



<style scoped>
/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
</style>