<template>
  <div class="bg-slate-800 text-slate-100 sm:flex sm:justify-between sm:items-baseline sm:px-4 sm:py-3">
    <div
      class="flex items-center justify-between px-4 py-3 sm:p-0"
    >
      <div class="text-green-100 font-semibold">GolfDoksy.cz</div>
      <div class="sm:hidden">
        <button @click="isOpen = !isOpen" class="block text-slate-300 hover:text-white focus:text-white">
          <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
            <path v-if="isOpen" fill-rule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"/>
            <path v-if="!isOpen" fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"/>
          </svg>
        </button>
      </div>
    </div>
    <nav :class="isOpen ? 'block' : 'hidden'" class="sm:block">
      <div class="px-2 pt-2 pb-4 sm:flex sm:p-0">
        <a href="#golf" class="block bg-slate-800 rounded px-2 py-1 hover:bg-slate-700">Golf</a>
        <a href="#fotbalgolf" class="block bg-slate-800 rounded px-2 py-1 mt-1 sm:mt-0 sm:ml-2 hover:bg-slate-700">Fotbalgolf</a>
        <a href="#kontakt" class="block bg-slate-800 rounded px-2 py-1 mt-1 sm:mt-0 sm:ml-2 hover:bg-slate-700">Kontakt</a>
        <!-- <AccountDropDown class="hidden sm:block sm:ml-4"/> -->
      </div>
      <!-- <div class="px-4 py-5 border-t border-slate-600 sm:hidden">
        <div  class="flex items-center">
            <img src="/images/avatar.jpeg" alt="Your avatar" class="h-8 w-8 rounded-full border-2 border-slate-600 object-cover">
            <span class="ml-3 font-semibold">Jan Doe</span>
        </div>
        <div class="mt-4">
            <a href="#" class="block text-slate-400 hover:text-white">Account settings</a>
            <a href="#" class="mt-2 block text-slate-400 hover:text-white">Support</a>
            <a href="#" class="mt-2 block text-slate-400 hover:text-white">Sign out</a>
        </div>
      </div> -->
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
        isOpen: false,
    };
  },
};
</script>